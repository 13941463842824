import * as React from "react";

export default function Video({ src, title, className = "" }) {
  return (
    <div className={"flex flex-col px-5 w-full " + className}>
      <div className="ascpect-ratio video-shadow z-0">
        <iframe
          src={src}
          className="h-full w-full absolute inset-0"
          title={title}
        />
      </div>
      <h3 className="text-center text-3xl text-shadow normal-case font-Arial z-10">
        {title}
      </h3>
    </div>
  );
}

import * as React from "react";

import Layout from "../components/layout";

import { useStaticQuery, graphql, Link } from "gatsby";

import Img from "gatsby-image";

import Video from "../components/video";

import ImageModal from "../components/imageModal";

import PortfolioYaml from "../../content/portfolio-videos.yaml";

export default function Portfolio() {
  const data = useStaticQuery(graphql`
    {
      sunset: file(relativePath: { eq: "sunset.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sunset2: file(relativePath: { eq: "sunset-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      backgroundEvents: file(relativePath: { eq: "background-events.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      photos: allFile(
        filter: { relativeDirectory: { regex: "/^photos/" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const sunset = data["sunset"].childImageSharp.fluid;
  const sunset2 = data["sunset2"].childImageSharp.fluid;
  const backgroundEvents = data["backgroundEvents"].childImageSharp.fluid;

  const sections = [
    {
      title: "event",
      photos: data["photos"].edges.filter(
        ({ node }) => node.relativeDirectory === "photos/event"
      ),
    },
    {
      title: "portrait | product",
      photos: data["photos"].edges.filter(
        ({ node }) => node.relativeDirectory === "photos/portrait"
      ),
    },
    {
      title: "landscape",
      photos: data["photos"].edges.filter(
        ({ node }) => node.relativeDirectory === "photos/landscape"
      ),
    },
  ];

  const [largeImage, enlargeImage] = React.useState("");

  function close() {
    enlargeImage("");
  }

  return (
    <Layout>
      <ImageModal src={largeImage} onClose={close} />
      <div className="py-5">
        <div className="w-min m-auto">
          <h1 className="text-center text-5xl">Portfolio</h1>
          <h3 className="text-3xl">
            <Link to="#photos">-Photo</Link>
          </h3>
          <h3 className="text-3xl">
            <Link to="#video">-Video</Link>
          </h3>
        </div>
      </div>
      <div className="relative">
        <div className="absolute inset-0 ">
          <Img
            fluid={sunset}
            alt="sunset"
            className="h-full"
            imgStyle={{
              zIndex: -1,
              objectPosition: "0 25%",
            }}
          />
        </div>
        <p className="text-center text-3xl px-5 py-40 sm:w-1/2 text-shadow font-Ailerons">
          Event | promotional | portrait | short story | documentary | landscape
        </p>
      </div>
      <h2
        className="border-b border-gray-500 text-5xl text-center py-10"
        id="video"
      >
        Video
      </h2>
      <h3 className="py-10 px-14 text-5xl">
        Short Story <i className="hidden sm:inline">|</i> documentary
        <i className="hidden sm:inline">|</i> promotional
      </h3>
      <div className="relative" id="stories">
        <div className="sticky inset-0 -z-1">
          <Img
            fluid={sunset2}
            alt="sunset2"
            className="h-screen"
            imgStyle={{
              zIndex: -1,
              filter: "brightness(0.7)",
            }}
          />
        </div>
        <div className="grid gap-y-10 sm:gap-x-20 py-10 sm:grid-cols-2 sm:px-10 container m-auto -mt-screen">
          {PortfolioYaml.videos.Stories.map(({ id, title }, index) => (
            <Video
              src={"https://www.youtube.com/embed/" + id}
              title={title}
              key={index}
            />
          ))}
        </div>
      </div>
      <h3 className="py-10 px-14 text-5xl">Events</h3>
      <div className="relative " id="events">
        <div className="sticky inset-0 -z-1">
          <Img
            fluid={backgroundEvents}
            alt="event background"
            className="h-screen"
            imgStyle={{
              zIndex: -1,
              filter: "brightness(0.7)",
            }}
          />
        </div>
        <div className="grid gap-y-10 sm:gap-x-20 pt-20 sm:grid-cols-2 sm:px-10 container m-auto w-full -mt-screen min-h-screen">
          {PortfolioYaml.videos.Events.map(({ id, title }, index) => (
            <Video
              src={"https://www.youtube.com/embed/" + id}
              title={title}
              key={index}
            />
          ))}
        </div>
      </div>

      <h2
        className="border-b border-gray-500 text-5xl text-center py-10"
        id="photos"
      >
        Photos
      </h2>

      {sections.map(({ title, photos }) => (
        <div key={title}>
          <h3 className="py-10 px-14 text-5xl">{title}</h3>
          <div className="grid  sm:grid-cols-10 grid-cols-6  auto-rows-min">
            {photos.map(({ node }, index) => (
              <div
                key={index}
                onClick={() => enlargeImage(node.childImageSharp.fluid)}
                onKeyDown={() => enlargeImage(node.childImageSharp.fluid)}
                role="button"
                tabIndex={0}
                className={
                  node.childImageSharp.fluid.aspectRatio < 1
                    ? "row-span-2 col-span-2"
                    : "col-span-2"
                }
              >
                <Img
                  fluid={node.childImageSharp.fluid}
                  alt={index.toString()}
                  fadeIn={false}
                  className="h-full"
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </Layout>
  );
}

import React, { useEffect, useRef } from "react";

import Img from "gatsby-image";

import { FaTimes } from "react-icons/fa";

export default function ImageModal({ src, onClose }) {
  const imageRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);
    if (src === "") {
      document.body.style.overflow = "scroll";
    } else {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "scroll";
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [src]);

  function clickOutside(event) {
    if (imageRef.current && !imageRef.current.contains(event.target)) {
      onClose();
    }
  }

  if (src === "") return null;

  return (
    <div className="fixed inset-0 h-screen w-full bg-blue z-40 sm:px-64 py-12 bg-opacity-80 bg-black">
      <FaTimes
        className="fixed right-5 top-5 text-5xl z-50 text-white"
        onClick={onClose}
      />
      <div ref={imageRef} className="h-full">
        <Img
          fluid={src}
          alt="image"
          className="h-full"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
    </div>
  );
}
